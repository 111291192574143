<template>
  <div class="about">
    <Header />
    <main>
      <!-- page title area start -->
      <section class="page__title p-relative d-flex align-items-center bk-page">
        <div class="page__title-bg bk-about"></div>
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page__title-content mt-100 text-center">
                <h2>about</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- page title area end -->

      <!-- team  -->
      <div
        class="main-services black-bg pt1 pt-120 pb-90"
        data-background="assets/img/pattern/pt1.png"
      >
        <div class="container">
          <div class="row mb-60">
            <div class="col-12">
              <div class="sec-wrapper text-start">
                <h2 class="section-title">{{ $t("about.title") }}</h2>
                <!-- <p v-html="$t('about.paragraph')" class="text-white"></p> -->
                <p class="text-white">
                  Well-B Space è pensato per i professionisti del benessere
                  della persona, di <strong>natura ordinistica</strong> e non.
                  Si rivolge a tutti coloro che hanno bisogno di lavorare in uno
                  <strong>spazio chiuso e riservato</strong>, nel quale operare
                  senza essere disturbati dai rumori esterni né mettere a
                  rischio la privacy dei pazienti. <br />
                  <br />
                </p>
                
                  <div class="main-services pb-90">
                    <div class="row">
                      <swiper
                        class="swiper col-10 m-auto"
                        :options="swiperOption"
                      >
                        <swiper-slide
                          ><img
                            src="../assets/img/about/about_1.webp"
                            alt="slide"
                        /></swiper-slide>
                        <swiper-slide
                          ><img
                            src="../assets/img/about/about_2.webp"
                            alt="slide"
                        /></swiper-slide>

                        <div
                          class="swiper-button-prev"
                          slot="button-prev"
                        ></div>
                        <div
                          class="swiper-button-next"
                          slot="button-next"
                        ></div>
                      </swiper>
                    </div>
                  </div>
                  <p class="text-white">
                  La presenza di diversi professionisti nello stesso posto,
                  tutti accomunati dalla ricerca del benessere fisico e
                  psicologico dei loro clienti, rende possibile creare
                  interessanti <strong>reti professionali</strong>. Interessanti
                  per i professionisti stessi, che possono così ottenere nuove
                  opportunità di lavoro. Interessanti anche per i pazienti
                  stessi, che vengono seguiti su più fronti da professionisti
                  che operano nello stesso posto e dialogando tra loro.
                  <br /><br />
                  Venire a visitare il coworking è semplicissimo. Lo
                  <strong>spazio di Milano</strong>, così come gli spazi che
                  arriveranno prossimamente,
                  <strong>è facile da raggiungere</strong> e immediatamente
                  riconoscibile. Inoltre, si pone l’obiettivo di essere
                  <strong>accessibile</strong> in autonomia anche da
                  <strong>persone con disabilità</strong>, grazie agli accessi
                  facilitati su strada e all’assenza di barriere
                  architettoniche. <br /><br />
                  </p>
                
                    <div class="main-services pt2 pb-90">
                      <div class="row">
                        <swiper
                          class="swiper col-10 m-auto"
                          :options="swiperOption"
                        >
                          <swiper-slide
                            ><img
                              src="../assets/img/about/about_3.webp"
                              alt="slide"
                          /></swiper-slide>
                          <swiper-slide
                            ><img
                              src="../assets/img/about/about_4.webp"
                              alt="slide"
                          /></swiper-slide>

                          <div
                            class="swiper-button-prev"
                            slot="button-prev"
                          ></div>
                          <div
                            class="swiper-button-next"
                            slot="button-next"
                          ></div>
                        </swiper>
                      </div>
                    </div>
                 <p class="text-white">

                   Per saperne di più sulle specifiche degli spazi, vi invitiamo
                   a visitare la pagina dedicata. Per avere un elenco completo di
                   servizi e disponibilità, registrati e crea la tua identità
                   digitale.
                 </p>
             
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- team  -->

      <!-- brand area end here -->
      <!--  <div class="brand-area bg-grey pt-100 pb-100">
         <div class="container">
             <div class="brand-active swiper-container">
                 <div class="swiper-wrapper align-items-center">
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".3s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-1.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".6s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-2.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay=".9s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-3.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.2s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-4.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.5s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-5.png" class="img-fluid" alt="img"></a>
                     </div>
                     <div class="brand-wrapper swiper-slide wow fadeInUp" data-wow-delay="1.8s" data-swiper-autoplay="10000">
                         <a href="#"><img src="assets/img/brand/brand-1.png" class="img-fluid" alt="img"></a>
                     </div>
                 </div>
             </div>
         </div>
     </div> -->
      <!-- brand area start here -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "About",
  components: {
    Header,
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
};
</script>
